@use '../abstract' as *;


.free-delivery-indicator {

  .message {
    margin-top: 5px;
    font-weight: 400;
    font-size: 0.95rem;
    letter-spacing: normal;
  }
}

