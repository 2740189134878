@use '../abstract' as *;

body {
  .mat-progress-bar {
    height: 6px;

    .mdc-linear-progress__bar-inner {
      border-top-width: 6px;
    }

    &.mat-done {
      .mat-progress-bar-buffer {
        background-color: #e1dede;
      }

      .mat-progress-bar-fill::after {
        background-color: #60a830;
      }
    }
  }
}

